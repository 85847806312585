import React from 'react';
import { getIn } from 'seamless-immutable';

const ZionHtml = ({ publication }) => {
  const content = getIn(publication, ['content']);

  if (!content) {
    return null;
  }

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default ZionHtml;
